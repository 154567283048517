






import { Component, Vue } from 'vue-property-decorator'

@Component

export default class Notfound extends Vue {
  created() {
    this['$router'].push('/').catch(() => { return })
  }
}
