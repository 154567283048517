import axios, { AxiosStatic } from 'axios'
import { Toast } from 'vant'
import store from '../store'

const { CancelToken } = axios

declare module 'vue/types/vue' {
  interface VueConstructor {
    $toast: any
  }
  interface Vue {
    axios: AxiosStatic
  }
}
interface ErrorMsg {
  type: string
  message: string
}
// 基础地址
const BASE_URL = process.env.VUE_APP_API_URL
// 错误返回
const errorMsg: ErrorMsg = {
  type: 'error',
  message: 'unhandle'
}
// 请求集合，防止重复点击
const requestUrls: string[] = []
let requestFlag: string = ''

/* 创建axios实例 */
const service = axios.create({
  timeout: 10000, // 请求超时时间
  baseURL: BASE_URL,
  withCredentials: true
})
const regex = /.*csrftoken=([^;.]*).*$/ // 用于从cookie中匹配 csrftoken值

// 请求加载框 如果不需要返回空
const handleLoading = (visable: boolean) => {
  // 自定义加载图标
  Toast.loading({
    message: 'Loading...',
    forbidClick: true
  })
}

/* request拦截器 */
service.interceptors.request.use(
  // eslint-disable-next-line consistent-return
  (config: any) => {
    handleLoading(true)
    // 重复点击start=======
    requestFlag = config.url + config.method
    if (requestUrls.indexOf(requestFlag) > -1) {
      config.cancelToken = new CancelToken((cancel: any) => {
        errorMsg.message = 'duplicate request'
        cancel('duplicate request')
      })
    } else {
      requestUrls.push(requestFlag)
      config.headers['X-CSRFToken'] = document.cookie.match(regex)
        ? document.cookie.match(regex)![1]
        : null
    }
    // 重复点击end=======
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)

/* respone拦截器 */
service.interceptors.response.use(
  (response: any) => {
    handleLoading(false)
    requestUrls.splice(requestUrls.indexOf(requestFlag), 1)
    if (response.data.error) {
      store.dispatch('app/handleError', response.data.error)
      return Promise.reject(response.data.error)
    }
    return response
  },
  (error: any) => {
    // 异常处理
    if (error.message && error.message === 'duplicate request') {
      errorMsg.message = 'duplicate request'
      return Promise.reject(errorMsg)
    }
    handleLoading(false)
    if (error.response && error.response.status === 413) {
      store.dispatch('app/handleError', 'ADV_FEEDBACK_IMAGE')
      return Promise.reject(errorMsg)
    }
    if (error.message && error.message.indexOf('timeout') > -1) {
      requestUrls.splice(requestUrls.indexOf(requestFlag), 1)
      errorMsg.message = 'request timeout'
      store.dispatch('app/handleError', errorMsg.message)
      return Promise.reject(errorMsg)
    }
    store.dispatch('app/handleError', errorMsg.message)
    requestUrls.splice(requestUrls.indexOf(requestFlag), 1)
    return Promise.reject(error)
  }
)

const http = service

export { http }
