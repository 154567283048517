const PROJECT_NAME = 'Advance'
const analyticsId = 'G-CN47F4EW2P'
/* eslint-disable */
export const getLang = (key?: string | undefined) => {
    const acceptableLang = ['pt', 'id', 'en','es']
    const lang = key || navigator.language.toLowerCase()
    for (let i = 0; i < acceptableLang.length; i++) {
        acceptableLang[i] = acceptableLang[i].toLowerCase()
        if (lang.indexOf(acceptableLang[i]) > -1) {
            return acceptableLang[i]
        }
    }
    if (lang === 'ind') {
        return 'id'
    }
    return 'en'
}
export const inGame = () => {
    if (window?.FreeFire || window?.webkit?.messageHandlers?.FreeFire) {
        return true
    } else {
        return false
    }
    // return true
}

// -------------GA start--------
/**
 *
 * @param {*} region
 * @param {*} uid
 */
export const initGA = (region: any, uid: any) => {
    window.gtag('config', analyticsId, {
        dimension1: region,
        page_title: PROJECT_NAME, // defined in webpack
        page_path: '/index.html'
    })
    window.gtag('set', {user_id: uid})
}
/**
 *
 * @param {*} event
 * @param {*} status
 */
export const addGA = (event: any, status: any) => {
    var report = {
        event_label: status
    }
    window.gtag('event', event, report)
}
/**
 *
 * @param {*} err
 */
export const errorGA = (err: any) => {
    window.gtag('event', 'exception', {
        description: err,
        fatal: false
    })
}
// -------------GA end--------

/**
 *
 * @param {*} url
 */
export const loadStyle = (url: string) => {
    var link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    var head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}
export const inlineStyle = (style: string) => {
    const styleTag = document.createElement('style')
    const head = document.getElementsByTagName('head')[0]
    styleTag.type = 'text/css'
    styleTag.innerHTML = style
    head.appendChild(styleTag)
}
export const compare = (key: string, order = 'asc') => {
    return (now: object, next: object) => {
        switch (order) {
            case 'des':
                return next[key] - now[key]
            case 'asc':
                return now[key] - next[key]
        }
    }
}

export const setCookie = (name: string, value: string) => {
    let expires = new Date()
    let oneDayTime = 24 * 60 * 60 * 1000 // ms
    expires.setTime(expires.getTime() + oneDayTime)
    document.cookie = `${name}=${escape(value)};expires=${expires[
        'toGMTString'
        ]()}`
}
export const getCookie = (name: string) => {
    let flag = `${name}=`
    let cookies = document.cookie.split(';')
    for (let item of cookies) {
        item = item.trim()
        if (item.indexOf(flag) > -1) {
            return unescape(item.substr(flag.length))
        }
    }
    return ''
}
export const deleteCookie = (name: string) => {
    let expires = new Date()
    let oneDayTime = 24 * 60 * 60 * 1000
    let value = getCookie(name)
    expires.setTime(expires.getTime() - oneDayTime)
    document.cookie = `${name}=${escape(value)};expires=${expires[
        'toGMTString'
        ]()}`
}
