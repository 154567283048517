






























































































































































import { setCookie, getCookie, inGame, addGA } from '../utils'
import { Vue, Component, Watch } from 'vue-property-decorator'
import ffButton from '@/components/public/Button.vue'
import { namespace } from 'vuex-class'
import { Toast } from 'vant'

const isDev = process.env['NODE_ENV'] == 'development'

const mockInfoData = {
  status: 'success',
  msg: '',
  data: {
    player: {
      uid: 1,
      gems: 100,
      can_refresh: true,
      init: true,
      free_refresh_count: 0,
    },
    common_config: {
      start_time: 1589177409,
      end_time: 1590387009,
      region_lang: 'US',
      region: 'US',
      lang: 'es',
      rule: 'rule',
      refresh_cost: 10,
      free_refresh_count: 3,
      flip_1_cost: 50,
      flip_2_cost: 80,
      flip_3_cost: 100,
    },
    policy_popup: {
      popup_title: 'test',
      popup_content: 'test',
      checked: true, //true 表示用户已经点击, false 表示还没点击
    }, // 如果没有开启政策弹窗是不会有 "policy_popup", 并不是空字典
    rewards: [
      {
        item_id: 0,
        item_name: 'name',
        item_num: 1,
        item_img: 'img',
        pool: 1,
        status: 0,
      },
      {
        item_id: 1,
        item_name: 'name',
        item_num: 1,
        item_img: 'img',
        pool: 2,
        status: 0,
      },
      {
        item_id: 1,
        item_name: 'name',
        item_num: 1,
        item_img: 'img',
        pool: 3,
        status: 0,
      },
    ],
    transify: { hello: '哈喽' },
    activation_code: '',
    is_ind: 1,
  },
}

const getMockData = () => Promise.resolve({ data: mockInfoData })
// then -> 同步处理

const appStore = namespace('app')

@Component({
  components: {
    ffButton,
  },
})
export default class Home extends Vue {
  @appStore.State((state) => state.transify) private transify: any
  @appStore.State((state) => state.isLogin) private isLogin: boolean
  @appStore.State((state) => state.downloadOpen) private downloadOpen: boolean
  @appStore.State((state) => state.registerOpen) private registerOpen: boolean
  @appStore.State((state) => state.isRegister) private isRegister: boolean
  @appStore.State((state) => state.loginFrom) private loginFrom: string
  @appStore.State((state) => state.indexInit) private indexInit: boolean
  @appStore.State((state) => state.has_activation_code)
  private has_activation_code: number
  @appStore.Action('login') private login!: Function
  @appStore.Action('setUserInfo') private setUserInfo!: Function
  @appStore.Action('setLoginFrom') private setLoginFrom!: Function

  private timer: any = null
  private activiteIndex: number = -1
  get benefit(): Array<object> {
    return [
      {
        title: this.transify['ADV_BENEFIT_SUBTITLE_1'] || '',
        context: this.transify['ADV_BENEFIT_SUBTITLE_1_CONTEXT'] || '',
      },
      {
        title: this.transify['ADV_BENEFIT_SUBTITLE_2'] || '',
        context: this.transify['ADV_BENEFIT_SUBTITLE_2_CONTEXT'] || '',
      },
      {
        title: this.transify['ADV_BENEFIT_SUBTITLE_3'] || '',
        context: this.transify['ADV_BENEFIT_SUBTITLE_3_CONTEXT'] || '',
      },
    ]
  }

  get isInGame(): boolean {
    return inGame()
  }

  setBenefitIdx(idx: number): string {
    return idx < 10 ? `0${idx + 1}.` : `${idx + 1}.`
  }

  eventLogin(type: string): void {
    // type 是渠道来源,区分fb/google
    // has_activation_code &&  info -> activation_code
    // if (this.has_activation_code === 1) {
    //   Toast(this.transify['ADV_TOAST01'])
    //   addGA('eventLogin', 'false')
    // } else {
    //   addGA('eventLogin', type)
    //   // 跳转登陆页
    //   this.login(type)
    // }

    // 未登录不做验证直接跳转
    addGA('eventLogin', type)
    this.login(type)
  }

  userInfo(accessToken: string): void {
    // Promise.resolve()
    //   .then(() => {
    //     if (isDev) {
    //       return getMockData()
    //     } else {
    //       return this['$http'].get(
    //         `/info?live_access_token=${accessToken}&login_from=${this.loginFrom}`
    //       )
    //     }
    //   })
    this['$http']
      .get(
        `/info?live_access_token=${accessToken}&login_from=${this.loginFrom}`
      )
      .then((res: any) => {
        const data = res.data || {}
        this.setUserInfo(res.data)
        if (this.has_activation_code == 1 && !res.data.activation_code) {
          const isInd = data?.is_ind == 1
          if (!isInd) {
            Toast(this.transify['ADV_TOAST01'])
          }
        }
      })
  }
  @Watch('indexInit', { immediate: false, deep: true })
  watchIndexInit(val: any, old: any) {
    if (val) {
      this.init()
    }
  }

  @Watch('isLogin', { immediate: false, deep: true })
  watchIsLogin(val: any, old: any) {
    if (val) {
      if (this.registerOpen) {
        if (!this.isRegister) {
          this['$router'].push({ path: 'register' }).catch(() => {
            return
          })
        } else {
          this['$router'].push({ path: 'download' }).catch(() => {
            return
          })
        }
      }
    }
  }

  init(): void {
    clearTimeout(this.timer)
    if (this.indexInit) {
      const accessToken =
        this['$route'].query['access_token'] || getCookie('liveAccessToken')
      if (this['$route'].query['access_token']) {
        setCookie('liveAccessToken', accessToken)
        inGame() && this.setLoginFrom('game')
      }
      if (getCookie('liveAccessToken')) {
        this.userInfo(accessToken)
      }
    } else {
      this.timer = setTimeout(() => {
        this.init()
      }, 1000)
    }
  }

  showContent(index: number): void {
    if (this.activiteIndex === index) {
      this.activiteIndex = -1
    } else {
      this.activiteIndex = index
    }
  }

  mounted(): void {
    this.init()
  }
}

function classDecorator<T extends { new (...args: any[]): {} }>(
  constructor: T
) {
  return class extends constructor {
    newProperty = 'new property'
    hello = 'override'
  }
}
