import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store: any = new Vuex.Store({
  modules: {
    app
  },
  strict: debug
})
export default store
