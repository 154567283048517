













































import { Component, Vue, Watch } from 'vue-property-decorator'
import ffHeader from '@/components/public/Header.vue'
import ErrorPage from '@/components/public/Error.vue'
import { Route } from 'vue-router'
import { Toast } from 'vant'
import { namespace } from 'vuex-class'
import { addGA, inGame } from '../../utils'
const appStore = namespace('app')
@Component({
  components: {
    ffHeader,
    ErrorPage
  }
})
export default class Layout extends Vue {
  private navShow: boolean = false
  private logoutshow: boolean = false
  private isNav: boolean = false
  private rewardsNav: boolean = false
  // @appStore.State((state) => state.is_ind) isInd: number | string
  @appStore.State((state) => state.region_ind) regionInd: number | string
  @appStore.State((state) => state.account_ind) accountInd: number | string
  @appStore.State(state => state.config) private config: any
  @appStore.State(state => state.transify) private transify: any
  @appStore.State(state => state.downloadOpen) private downloadOpen: boolean
  @appStore.State(state => state.feedbackOpen) private feedbackOpen: boolean
  @appStore.State(state => state.apkLink) private apkLink: string
  @appStore.State(state => state.gameLogin) private gameLogin: boolean
  @appStore.State(state => state.loginFrom) private loginFrom: string
  @appStore.State(state => state.userEmail) private userEmail: string
  @appStore.State(state => state.isLogin) private isLogin: string
  @appStore.Action('logout') private logout!: Function
  @Watch('$route')
  toggleRouter(val: Route): void {
    if (val.path === '/register' || (val.path === '/' && !this.isLogin)) {
      this.isNav = false
    } else {
      this.isNav = true
      if (val.path === '/rewards') {
        this.rewardsNav = true
      } else {
        this.rewardsNav = false
      }
    }
  }
  get initNav(): Array<object> {
    let navList: Array<object> = []
    navList.push({
      title: this.transify['ADV_MENU_DOWNLOAD'],
      path: '/download'
    })
    navList.push({
      title: this.transify['ADV_MENU_BUG'],
      path: '/feedback'
    })
    navList = navList.concat([
      {
        title: this.transify['ADV_MENU_HISTORY'],
        path: '/profile'
      },
      {
        title: this.transify['ADV_MENU_REWARDS'],
        path: '/rewards'
      }
    ])
    navList.push({
      title: this.transify['ADV_MENU_RETURN'],
      path: '/'
    })

    return navList
  }

  get isInGame(): boolean {
    return inGame()
  }

  get isInd(): boolean {
    return this.regionInd == 1 || this.accountInd == 1
  }
  toggleNav(): void {
    this.navShow = !this.navShow
    this.logoutshow = false
  }
  toggleLogout(): void {
    this.logoutshow = !this.logoutshow
    this.navShow = false
  }
  pustRouter(path: any) {
    addGA('choosePath', path)
    if (path === '/feedback') {
      if (!this.feedbackOpen) return
      if (!this.gameLogin) {
        Toast(this.transify['ADV_DOWNLOAD_REPORTBUG_HINT'])
        return
      }
    }
    this.closeNav()
    this['$router'].push(path).catch(() => {
      return
    })
  }
  closeNav(): void {
    this.navShow = false
  }
  toHome(): void {
    this['$router'].push({ path: '/' }).catch(() => {
      return
    })
  }
  logoutAccount(): void {
    this.logout()
    this['$router'].push({ path: '/' }).catch(() => {
      return
    })
    this.logoutshow = false
  }
  clearshow(): void {
    this.navShow = false
    this.logoutshow = false
  }
  mounted(): void {
    this.toggleRouter(this['$route'])
  }
}
