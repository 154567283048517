
















import {
  Component,
  Vue,
} from 'vue-property-decorator/lib/vue-property-decorator'
import { namespace } from 'vuex-class'

const appStore = namespace('app')

@Component({
    name:'limit-page'
})
export default class Error extends Vue {
    @appStore.State((state) => state.transify) private transify: any

}
