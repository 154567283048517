







import { Component, Vue, Watch } from 'vue-property-decorator'
import Layout from '@/components/public/Layout.vue'
import { namespace } from 'vuex-class'

import { getLang } from '@/utils/index.ts'

const appStore = namespace('app')

@Component({
  components: {
    Layout
  }
})
export default class App extends Vue {
  private checkedTheme: string = 'theme-one'
  @appStore.State(state => state.isLogin) private isLogin: boolean
  @appStore.State(state => state.lang) private lang: string | null
  @appStore.Action('getConfig') private getConfig!: Function

  configState = false
  @Watch('$route')
  routechange(to: any, from: any) {
    if (this.isLogin) {
      this['$router'].push({ path: to.path }).catch(() => {
        return
      })
    } else {
      this['$router'].push({ path: '/' }).catch(() => {
        return
      })
    }
  }

  created(): void {
    let lang
    const gameLang = this['$route'].query['lang']
    if (gameLang) {
      lang = getLang(gameLang)
    } else if (this.lang) {
      lang = this.lang
    } else {
      lang = getLang()
    }
    this.getConfig(lang).finally(() => {
      // 无视请求状态 等待接口完成加载页面
       this.configState = true

    })
    if (sessionStorage.getItem('store')) {
      this['$store'].replaceState(Object.assign({}, this['$store'].state, JSON.parse(sessionStorage.getItem('store') || '')))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this['$store'].state.app))
    })
    window.addEventListener('pagehide', () => {
      sessionStorage.setItem('store', JSON.stringify(this['$store'].state.app))
    })
  }
}
