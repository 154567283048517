







import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class Button extends Vue {
  @Prop({
    type: String,
    default: 'normal',
    required: false
  })
  styles!: string
  @Prop({
    type: String,
    default: 'button',
    required: false
  })
  type!: string
  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  disabled!: boolean
}
